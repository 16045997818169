import { defineComponent as _defineComponent } from 'vue'
import { reactive } from 'vue'
import { useShowModal, useConfirmWithPromise } from '@/composables/useModalOpener'
import useGlobal from '@/composables/useGlobal'
import { $vfm } from 'vue-final-modal'


export default /*@__PURE__*/_defineComponent({
  __name: 'RequestFormWrapper',
  props: {
  periods: {
    type: Array,
  },
  eventId: {
    type: Number,
  },
  price: {
    type: Number,
  },
  videoPrice: {
    type: Number,
  },
  actualPrice: {
    type: Number,
  },
  registrationCompleted: {
    type: Boolean,
  },
  pastEvent: {
    type: Boolean,
  },
  canBuyVideo: {
    type: Boolean,
  },
  userHasEvent: {
    type: Boolean,
  },
  cartType: {
    type: String,
  },
  registrationTime: {
    type: String,
  },
},
  setup(__props, { expose: __expose }) {
  __expose();

const page403Url = window.page403Url
const userIsAuthenticated = window.isAuthenticated

const props = __props

const { $t } = useGlobal()
const formdata = reactive({
  period: {},
})

const selectDate = (period: any) => {
  formdata.period = period
}

const confirmRegistration = async () => {
  // console.log('confirmRegistration')
  // console.log('formdata.period', formdata.period)
  if (!userIsAuthenticated) {
    const message = {
      title: $t('Увага!'),
      text: $t('Необхідно авторизуватися для створення заявки. Продовжити?'),
    }
    await useConfirmWithPromise(message)
    window.location.href = page403Url
  } else {
    if (formdata.period.id || props.canBuyVideo) {
      $vfm.show({
        component: 'UiModalContainer',
      }, {
        classes: 'modal--size_lg',
        component: 'Events/RequestFormEvent',
        periodId: formdata.period.id,
        eventId: props.eventId,
      })
    } else {
      const message = {
        title: $t('Спочатку оберіть дату на яку бажаете записатись'),
      }

      useShowModal(message)
    }
  }
}

const __returned__ = { page403Url, userIsAuthenticated, props, $t, formdata, selectDate, confirmRegistration, reactive, get useShowModal() { return useShowModal }, get useConfirmWithPromise() { return useConfirmWithPromise }, get useGlobal() { return useGlobal }, get $vfm() { return $vfm } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})
import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, vModelCheckbox as _vModelCheckbox, withCtx as _withCtx, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "ds-panel ds-panel--space_xl ds-panel--space_2xl-xl" }
const _hoisted_2 = { class: "ds-panel__element ds-panel__element--offset_bottom" }
const _hoisted_3 = { class: "ds-caption ds-caption--size_2xl ds-caption--size_3xl-lg ds-caption--size_xxl-xl ds-caption--weight_semi-bold ds-caption--ff_bahnschrift ds-caption--appearance_center" }
const _hoisted_4 = { class: "ds-panel ds-panel--space_4xl ds-panel--space_6xl-xl" }
const _hoisted_5 = { class: "ds-panel__element ds-panel__element--offset_bottom" }
const _hoisted_6 = { class: "g-row g-row--appearance_spaced g-row--justify_center" }
const _hoisted_7 = { class: "g-cell g-cols g-cols--12 g-cols--8-md" }
const _hoisted_8 = { class: "ds-caption ds-caption--size_sm ds-caption--size_md-xl ds-caption--appearance_center ds-caption--lh_1" }
const _hoisted_9 = {
  key: 0,
  class: "ds-caption ds-caption--size_sm ds-caption--size_md-xl ds-caption--appearance_center ds-caption--lh_1 ds-caption--weight_semi-bold ds-caption--color_main"
}
const _hoisted_10 = { class: "g-row g-row--appearance_spaced g-row--space_md" }
const _hoisted_11 = { class: "g-cell g-cols g-cols--12 g-cols--6-md" }
const _hoisted_12 = { class: "g-cell g-cols g-cols--12 g-cols--6-md" }
const _hoisted_13 = { class: "g-cell g-cols g-cols--12 g-cols--6-md" }
const _hoisted_14 = { class: "g-cell g-cols g-cols--12 g-cols--6-md" }
const _hoisted_15 = { class: "g-cell g-cols g-cols--12 g-cols--6-md" }
const _hoisted_16 = { class: "g-cell g-cols g-cols--12 g-cols--6-md" }
const _hoisted_17 = { class: "g-cell g-cols g-cols--12 g-cols--6-md" }
const _hoisted_18 = { class: "g-cell g-cols g-cols--12 g-cols--6-md" }
const _hoisted_19 = { class: "ds-panel ds-panel--space_3xl ds-panel--space_4xl-xl" }
const _hoisted_20 = { class: "ds-panel__element ds-panel__element--offset_top" }
const _hoisted_21 = { class: "g-row g-row--justify_center" }
const _hoisted_22 = { class: "g-cell" }
const _hoisted_23 = { class: "d-control-checkbox d-control-checkbox--variant_flex" }
const _hoisted_24 = { class: "d-control-checkbox__content" }
const _hoisted_25 = { class: "ds-panel ds-panel--space_md" }
const _hoisted_26 = { class: "ds-panel__element ds-panel__element--offset_top" }
const _hoisted_27 = { class: "error-label" }
const _hoisted_28 = { class: "ds-panel ds-panel--space_5xl" }
const _hoisted_29 = { class: "ds-panel__element ds-panel__element--offset_top" }
const _hoisted_30 = { class: "g-row g-row--appearance_spaced g-row--space_xl g-row--justify_center" }
const _hoisted_31 = { class: "g-cell g-cols g-cols--12 g-cols--6-md" }
const _hoisted_32 = { class: "ds-panel ds-panel--space_lg" }
const _hoisted_33 = { class: "ds-panel__element ds-panel__element--offset_top" }
const _hoisted_34 = { class: "g-row g-row--justify_center" }
const _hoisted_35 = { class: "g-cell" }
const _hoisted_36 = { class: "error-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_control_input = _resolveComponent("control-input")!
  const _component_control_select = _resolveComponent("control-select")!
  const _component_VeeField = _resolveComponent("VeeField")!
  const _component_VeeError = _resolveComponent("VeeError")!
  const _component_control_button = _resolveComponent("control-button")!
  const _component_VeeForm = _resolveComponent("VeeForm")!
  const _directive_maska = _resolveDirective("maska")!

  return (_openBlock(), _createBlock(_component_VeeForm, { onSubmit: $setup.send }, {
    default: _withCtx(({ errors }) => [
      _createElementVNode("div", {
        class: _normalizeClass(["i-preloader", { 'is-active': $setup.isLoad }])
      }, _cache[10] || (_cache[10] = [
        _createElementVNode("div", { class: "i-preloader__item" }, null, -1)
      ]), 2),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h2", _hoisted_3, _toDisplayString($setup.$t("Заявка на участь")), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("p", _hoisted_8, _toDisplayString($setup.$t("Якщо вас зацікавила дана тема, залиште свої дані, і ми з радістю повідомимо вам, щойно оголосимо новий набір")), 1),
              (!$setup.userIsAuthenticated)
                ? (_openBlock(), _createElementBlock("p", _hoisted_9, _toDisplayString($setup.$t("Увага! Щоб подати заявку, необхідно авторизуватися!")), 1))
                : _createCommentVNode("", true)
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, [
          _createVNode(_component_control_input, {
            name: "firstName",
            type: "text",
            rules: "required|max:150",
            modelValue: $setup.formdata.firstName,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.formdata.firstName) = $event)),
            errors: errors,
            "input-label": $setup.$t("Ім'я"),
            placeholder: $setup.$t("Введіть ім'я")
          }, null, 8, ["modelValue", "errors", "input-label", "placeholder"])
        ]),
        _createElementVNode("div", _hoisted_12, [
          _createVNode(_component_control_input, {
            name: "lastName",
            type: "text",
            rules: "required|max:150",
            modelValue: $setup.formdata.lastName,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.formdata.lastName) = $event)),
            errors: errors,
            "input-label": $setup.$t("Прізвище"),
            placeholder: $setup.$t("Введіть прізвище")
          }, null, 8, ["modelValue", "errors", "input-label", "placeholder"])
        ]),
        _createElementVNode("div", _hoisted_13, [
          _createVNode(_component_control_input, {
            name: "email",
            type: "email",
            rules: "required|email",
            modelValue: $setup.formdata.email,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.formdata.email) = $event)),
            errors: errors,
            "input-label": $setup.$t("Е-mail"),
            placeholder: $setup.$t("Введіть e-mail")
          }, null, 8, ["modelValue", "errors", "input-label", "placeholder"])
        ]),
        _createElementVNode("div", _hoisted_14, [
          _withDirectives(_createVNode(_component_control_input, {
            name: "phone",
            type: "tel",
            rules: "required|maskedPhone:12",
            modelValue: $setup.formdata.phone,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.formdata.phone) = $event)),
            errors: errors,
            "input-label": $setup.$t("Телефон"),
            placeholder: $setup.$t("Введіть Телефон")
          }, null, 8, ["modelValue", "errors", "input-label", "placeholder"]), [
            [_directive_maska, '+38 (###) ###-##-##']
          ])
        ]),
        _createElementVNode("div", _hoisted_15, [
          _createVNode(_component_control_select, {
            modelValue: $setup.formdata.area,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($setup.formdata.area) = $event)),
            name: "area",
            rules: "required",
            "option-label": "description",
            "allow-empty": false,
            options: $setup.choices.area,
            errors: errors,
            searchable: true,
            "input-label": $setup.$t("Область"),
            placeholder: $setup.$t("Оберіть область"),
            onSelect: $setup.areaChangeHandler
          }, null, 8, ["modelValue", "options", "errors", "input-label", "placeholder"])
        ]),
        _createElementVNode("div", _hoisted_16, [
          _createVNode(_component_control_select, {
            modelValue: $setup.formdata.settlement,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($setup.formdata.settlement) = $event)),
            name: "settlement",
            rules: "required",
            "option-label": "settlement",
            "allow-empty": false,
            searchable: true,
            disabled: !$setup.formdata.area,
            options: $setup.choices.settlement,
            errors: errors,
            "ajax-results": true,
            "input-label": $setup.$t("Населений пункт"),
            placeholder: $setup.$t("Оберіть населений пункт"),
            onSearchChange: $setup.searchSettlementDebounce
          }, null, 8, ["modelValue", "disabled", "options", "errors", "input-label", "placeholder", "onSearchChange"])
        ]),
        _createElementVNode("div", _hoisted_17, [
          _createVNode(_component_control_select, {
            modelValue: $setup.formdata.institutionType,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (($setup.formdata.institutionType) = $event)),
            name: "institutionType",
            rules: "required",
            "option-label": "label",
            "allow-empty": false,
            options: $setup.choices.institutionType,
            errors: errors,
            "input-label": $setup.$t("Тип учбового закладу"),
            placeholder: $setup.$t("Оберіть тип учбового закладу")
          }, null, 8, ["modelValue", "options", "errors", "input-label", "placeholder"])
        ]),
        _createElementVNode("div", _hoisted_18, [
          _createVNode(_component_control_input, {
            name: "institution",
            type: "text",
            rules: "required|max:150",
            modelValue: $setup.formdata.institution,
            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (($setup.formdata.institution) = $event)),
            errors: errors,
            "input-label": $setup.$t("Навчальний заклад"),
            placeholder: $setup.$t("Введіть навчальний заклад")
          }, null, 8, ["modelValue", "errors", "input-label", "placeholder"])
        ])
      ]),
      _createElementVNode("div", _hoisted_19, [
        _createElementVNode("div", _hoisted_20, [
          _createElementVNode("div", _hoisted_21, [
            _createElementVNode("div", _hoisted_22, [
              _createVNode(_component_VeeField, {
                type: "checkbox",
                name: "acceptPolicy",
                rules: "required",
                "unchecked-value": false,
                modelValue: $setup.formdata.acceptPolicy,
                "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => (($setup.formdata.acceptPolicy) = $event))
              }, {
                default: _withCtx(() => [
                  _createElementVNode("label", _hoisted_23, [
                    _withDirectives(_createElementVNode("input", {
                      class: "d-control-checkbox__element",
                      name: "acceptPolicy",
                      type: "checkbox",
                      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (($setup.formdata.acceptPolicy) = $event))
                    }, null, 512), [
                      [_vModelCheckbox, $setup.formdata.acceptPolicy]
                    ]),
                    _cache[11] || (_cache[11] = _createElementVNode("span", { class: "d-control-checkbox__label" }, null, -1)),
                    _createElementVNode("span", _hoisted_24, [
                      _createVNode($setup["PrivacyPolicy"], {
                        text: $setup.$t("Реєструючись на сайті я погоджуюсь із")
                      }, null, 8, ["text"])
                    ])
                  ])
                ]),
                _: 1
              }, 8, ["modelValue"]),
              _createVNode(_component_VeeError, { name: "acceptPolicy" }, {
                default: _withCtx(({ message }) => [
                  _createElementVNode("div", _hoisted_25, [
                    _createElementVNode("div", _hoisted_26, [
                      _createElementVNode("span", _hoisted_27, _toDisplayString(message), 1)
                    ])
                  ])
                ]),
                _: 1
              })
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_28, [
        _createElementVNode("div", _hoisted_29, [
          _createElementVNode("div", _hoisted_30, [
            _createElementVNode("div", _hoisted_31, [
              _createVNode(_component_control_button, {
                disabled: $setup.isLoad,
                type: "submit"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString($setup.$t("Залишити заявку")), 1)
                ]),
                _: 1
              }, 8, ["disabled"])
            ])
          ])
        ]),
        _createVNode(_component_VeeError, { name: "nonFieldErrors" }, {
          default: _withCtx(({ message }) => [
            _createElementVNode("div", _hoisted_32, [
              _createElementVNode("div", _hoisted_33, [
                _createElementVNode("div", _hoisted_34, [
                  _createElementVNode("div", _hoisted_35, [
                    _createElementVNode("span", _hoisted_36, _toDisplayString(message), 1)
                  ])
                ])
              ])
            ])
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }))
}
import { defineComponent as _defineComponent } from 'vue'
import { debounce } from 'lodash'
import { ref, reactive, watch, defineAsyncComponent, nextTick, onMounted } from 'vue'
import { useErrorsGetter, useParsedSetter } from '@/components/Forms/FormMixin'
import { eventRequestCreateResource } from '@/services/event.service'
import useGlobal from '@/composables/useGlobal'
import { useShowModal, useConfirmWithPromise } from '@/composables/useModalOpener'
import { useUserStore } from '@/store/user'
import { clearPhone, prettifyPhone } from '@/utils/transformers'
import { locationsAreaListResource, locationsSettlementListResource } from '@/services/locations.service'
import { institutionTypeListResource } from '@/services/authenticate.service'


export default /*@__PURE__*/_defineComponent({
  __name: 'RequestFormCourse',
  props: {
  eventId: {
    type: Number,
  },
},
  setup(__props, { expose: __expose }) {
  __expose();

const PrivacyPolicy = defineAsyncComponent(() => import('@/components/Authentication/PrivacyPolicy'))

const props = __props

const { $t } = useGlobal()
const userStore = useUserStore()

const isLoad = ref(false)

const formdata = reactive({
  event: props.eventId,
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  settlement: null,
  area: null,
  institutionType: null,
  institution: '',
  privacyPolicy: false,
  pageFrom: window.location.href,
})

const choices = reactive({
  area: [],
  settlement: [],
  institutionType: [],
})

const page403Url = window.page403Url
const userIsAuthenticated = window.isAuthenticated

const prefillUserInfo = val => {
  if (!window.isAuthenticated) return

  formdata.firstName = val.info.firstName
  formdata.lastName = val.info.lastName
  formdata.email = val.info.email

  let userPhone = val.info.phone

  if (userPhone) {
    userPhone = prettifyPhone(userPhone)
  }

  formdata.phone = userPhone

  formdata.institution = val.info.institution || ''
}

watch(() => userStore.getUserInfo, val => {
  prefillUserInfo(val)
}, { immediate: true, deep: true })

const prepareFormdata = () => {
  const preparedFormdata = { ...formdata }

  preparedFormdata.phone = clearPhone(preparedFormdata.phone)

  preparedFormdata.settlement = preparedFormdata.settlement.settlement
  preparedFormdata.area = preparedFormdata.area.id
  preparedFormdata.institutionType = preparedFormdata.institutionType.value

  return preparedFormdata
}

const resetForm = () => {
  prefillUserInfo(userStore.getUserInfo)
}

const send = async (sendData: object, control: any) => {

  if (!userIsAuthenticated) {
    const message = {
      title: $t('Увага!'),
      text: $t('Необхідно авторизуватися для створення заявки. Продовжити?'),
    }
    await useConfirmWithPromise(message)
    window.location.href = page403Url
  } else {

    control.setFieldError('nonFieldErrors', '')

    if (isLoad.value) return

    isLoad.value = true

    const preparedFormdata = prepareFormdata()

    eventRequestCreateResource.execute({}, preparedFormdata).then(res => {
      const { data: { redirect } } = res

      if (redirect) {
        window.location = redirect.location
      } else {
        const message = {
          title: $t('Дякуємо за реєстрацію на івент'),
          text: $t('Ваша заявка прийнята'),
        }

        useShowModal(message)

        if (props.close) {
          props.close()
        } else {
          control.resetForm()

          nextTick(() => {
            resetForm()
          })
        }
      }
    }).catch(async (e: object) => {
      const parsed = await useErrorsGetter(e)

      useParsedSetter(parsed, control.setFieldError)
    }).finally(() => {
      isLoad.value = false
    })
  }
}

const getAreaList = async () => {
  const { data: { items } } = await locationsAreaListResource.execute()

  choices.area = items
}

const searchSettlement = async (val: string) => {
  if (val && val.length >= 2) {
    const formdataToSend = {
      ref: formdata.area.ref,
      query: val,
    }
    locationsSettlementListResource.execute({}, formdataToSend).then(res => {
      const { data: { item } } = res

      choices.settlement = item.settlements
    }).catch(e => {
      console.log(e)
    })
  } else {
    choices.settlement = []
  }
}

const searchSettlementDebounce = debounce(searchSettlement, 100)

const areaChangeHandler = () => {
  formdata.settlement = null
  choices.settlement = []
}

const getInstitutionType = async () => {
  const { data: { item } } = await institutionTypeListResource.execute()

  choices.institutionType = item.choices
}

onMounted(() => {
  // console.log('page403Url', page403Url)
  getAreaList()
  getInstitutionType()
})

const __returned__ = { PrivacyPolicy, props, $t, userStore, isLoad, formdata, choices, page403Url, userIsAuthenticated, prefillUserInfo, prepareFormdata, resetForm, send, getAreaList, searchSettlement, searchSettlementDebounce, areaChangeHandler, getInstitutionType, get debounce() { return debounce }, ref, reactive, watch, defineAsyncComponent, nextTick, onMounted, get useErrorsGetter() { return useErrorsGetter }, get useParsedSetter() { return useParsedSetter }, get eventRequestCreateResource() { return eventRequestCreateResource }, get useGlobal() { return useGlobal }, get useShowModal() { return useShowModal }, get useConfirmWithPromise() { return useConfirmWithPromise }, get useUserStore() { return useUserStore }, get clearPhone() { return clearPhone }, get prettifyPhone() { return prettifyPhone }, get locationsAreaListResource() { return locationsAreaListResource }, get locationsSettlementListResource() { return locationsSettlementListResource }, get institutionTypeListResource() { return institutionTypeListResource } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "ds-panel ds-panel--space_3xl ds-panel--space_4xl-xl"
}
const _hoisted_2 = { class: "ds-panel__element ds-panel__element--offset_top" }
const _hoisted_3 = { class: "g-row g-row--space_sm g-row--appearance_spaced" }
const _hoisted_4 = { class: "g-cell g-cols" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "date-card__item ds-caption ds-caption--size_md ds-caption--size_2sm ds-caption--size_md-lg ds-caption--size_4md-hd ds-caption--appearance_center ds-caption--weight_semi-bold ds-caption--lh_5" }
const _hoisted_7 = { class: "date-card__item ds-caption ds-caption--size_2xs ds-caption--size_xs ds-caption--size_2xs-lg ds-caption--size_md-hd ds-caption--appearance_center ds-caption--weight_semi-bold" }
const _hoisted_8 = {
  key: 1,
  class: "ds-panel ds-panel--space_3xl ds-panel--space_4xl-xl"
}
const _hoisted_9 = { class: "ds-panel__element ds-panel__element--offset_top" }
const _hoisted_10 = { class: "ds-caption ds-caption--size_2xl ds-caption--size_3xl-xl ds-caption--weight_semi-bold ds-caption--ff_bahnschrift" }
const _hoisted_11 = {
  key: 2,
  class: "ds-panel ds-panel--space_3xl ds-panel--space_4xl-xl"
}
const _hoisted_12 = { class: "ds-panel__element ds-panel__element--offset_top" }
const _hoisted_13 = { class: "conditions" }
const _hoisted_14 = { class: "conditions__option conditions__option--variant_attantion" }
const _hoisted_15 = { class: "conditions__option-icon" }
const _hoisted_16 = { class: "conditions__option-content" }
const _hoisted_17 = { class: "ds-caption ds-caption--size_2xs ds-caption--size_sm-lg ds-caption--color_main ds-caption--weight_semi-bold" }
const _hoisted_18 = {
  key: 3,
  class: "ds-panel ds-panel--space_3xl ds-panel--space_4xl-xl"
}
const _hoisted_19 = { class: "ds-panel__element ds-panel__element--offset_top" }
const _hoisted_20 = { class: "conditions" }
const _hoisted_21 = { class: "conditions__option conditions__option--variant_attantion" }
const _hoisted_22 = { class: "conditions__option-icon" }
const _hoisted_23 = { class: "conditions__option-content" }
const _hoisted_24 = { class: "ds-caption ds-caption--size_2xs ds-caption--size_sm-lg ds-caption--color_main ds-caption--weight_semi-bold" }
const _hoisted_25 = {
  key: 4,
  class: "ds-panel ds-panel--space_3xl ds-panel--space_4xl-xl"
}
const _hoisted_26 = { class: "ds-panel__element ds-panel__element--offset_top" }
const _hoisted_27 = { class: "event-details__button-wrapper" }
const _hoisted_28 = { class: "ds-caption ds-caption--size_xs ds-caption--size_sm-xl ds-caption--color_relief-2 ds-caption--appearance_center" }
const _hoisted_29 = { class: "ds-panel ds-panel--space_xs" }
const _hoisted_30 = { class: "ds-panel__element ds-panel__element--offset_top" }
const _hoisted_31 = { class: "ds-panel ds-panel--space_3xl ds-panel--space_4xl-xl" }
const _hoisted_32 = { class: "ds-panel__element ds-panel__element--offset_top" }
const _hoisted_33 = {
  key: 0,
  class: "event-details__button-wrapper"
}
const _hoisted_34 = { key: 1 }
const _hoisted_35 = { class: "ds-panel ds-panel--space_2xl ds-panel--space_3xl-xl" }
const _hoisted_36 = { class: "ds-panel__element ds-panel__element--offset_bottom" }
const _hoisted_37 = { class: "ds-caption ds-caption--size_2xl ds-caption--size_3xl-xl ds-caption--weight_semi-bold" }
const _hoisted_38 = { key: 0 }
const _hoisted_39 = { class: "event-details__button-wrapper ma ma--top-15" }
const _hoisted_40 = { key: 1 }
const _hoisted_41 = { class: "event-details__button-wrapper ma ma--top-15" }
const _hoisted_42 = {
  key: 1,
  class: "event-details__button-wrapper ma ma--top-15"
}
const _hoisted_43 = {
  key: 2,
  class: "event-details__button-wrapper"
}
const _hoisted_44 = { key: 0 }
const _hoisted_45 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_attantion = _resolveComponent("icon-attantion")!
  const _component_ui_percentage = _resolveComponent("ui-percentage")!
  const _component_control_button = _resolveComponent("control-button")!
  const _component_modal_trigger = _resolveComponent("modal-trigger")!
  const _component_cart_button_add = _resolveComponent("cart-button-add")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($props.periods && $props.periods.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.periods, (period) => {
                return (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createElementVNode("div", {
                    class: _normalizeClass(["date-card", { 'is-disabled': $props.registrationCompleted || !period.is_active, 'is-active': period.id === $setup.formdata.period.id }]),
                    onClick: _withModifiers(($event: any) => ($setup.selectDate(period)), ["prevent"])
                  }, [
                    _createElementVNode("p", _hoisted_6, _toDisplayString(period.date), 1),
                    _createElementVNode("p", _hoisted_7, _toDisplayString(period.time), 1)
                  ], 10, _hoisted_5)
                ]))
              }), 256))
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    ($props.registrationTime)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("p", _hoisted_10, _toDisplayString($props.registrationTime), 1)
          ])
        ]))
      : _createCommentVNode("", true),
    (!$setup.formdata.period.id)
      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("div", _hoisted_14, [
                _createElementVNode("div", _hoisted_15, [
                  _createVNode(_component_icon_attantion)
                ]),
                _createElementVNode("div", _hoisted_16, [
                  _createElementVNode("p", _hoisted_17, _toDisplayString($setup.$t("Щоб зареєструватися, необхідно обрати бажану дату")), 1)
                ])
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    (!$setup.userIsAuthenticated)
      ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
          _createElementVNode("div", _hoisted_19, [
            _createElementVNode("div", _hoisted_20, [
              _createElementVNode("div", _hoisted_21, [
                _createElementVNode("div", _hoisted_22, [
                  _createVNode(_component_icon_attantion)
                ]),
                _createElementVNode("div", _hoisted_23, [
                  _createElementVNode("p", _hoisted_24, _toDisplayString($setup.$t("Щоб подати заявку, необхідно авторизуватися!")), 1)
                ])
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    ($setup.formdata.period.id && $props.actualPrice)
      ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
          _createElementVNode("div", _hoisted_26, [
            _createElementVNode("div", _hoisted_27, [
              _createElementVNode("p", _hoisted_28, [
                _createElementVNode("span", null, _toDisplayString($setup.$t("Зареєстровано:")), 1),
                _cache[0] || (_cache[0] = _createTextVNode()),
                _createElementVNode("span", null, _toDisplayString($setup.formdata.period.number_registrants), 1),
                _cache[1] || (_cache[1] = _createTextVNode(" ")),
                _createElementVNode("span", null, _toDisplayString($setup.$t("із")), 1),
                _cache[2] || (_cache[2] = _createTextVNode(" ")),
                _createElementVNode("span", null, _toDisplayString($setup.formdata.period.number_participants), 1)
              ]),
              _createElementVNode("div", _hoisted_29, [
                _createElementVNode("div", _hoisted_30, [
                  _createVNode(_component_ui_percentage, {
                    total: $setup.formdata.period.number_participants,
                    current: $setup.formdata.period.number_registrants
                  }, null, 8, ["total", "current"])
                ])
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_31, [
      _createElementVNode("div", _hoisted_32, [
        ($setup.formdata.period.join_url)
          ? (_openBlock(), _createElementBlock("div", _hoisted_33, [
              _createVNode(_component_control_button, {
                tag: "a",
                href: $setup.formdata.period.join_url,
                target: "_blank"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString($setup.$t("До події")), 1)
                ]),
                _: 1
              }, 8, ["href"])
            ]))
          : ($props.registrationCompleted || $props.pastEvent)
            ? (_openBlock(), _createElementBlock("div", _hoisted_34, [
                _createElementVNode("div", _hoisted_35, [
                  _createElementVNode("div", _hoisted_36, [
                    _createElementVNode("p", _hoisted_37, _toDisplayString($setup.$t("Реєстрація завершена")), 1)
                  ])
                ]),
                (!$props.userHasEvent)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      ($props.canBuyVideo)
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                            ($props.actualPrice > 0)
                              ? (_openBlock(), _createBlock(_component_cart_button_add, {
                                  key: 0,
                                  "item-id": $props.eventId,
                                  type: $props.cartType,
                                  period: $setup.formdata.period.id
                                }, {
                                  default: _withCtx((props) => [
                                    (props.isInCart)
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_38, [
                                          _createVNode(_component_modal_trigger, {
                                            component: "Cart/View",
                                            classes: ['modal--size_xl']
                                          }, {
                                            default: _withCtx(({ open }) => [
                                              _createElementVNode("div", _hoisted_39, [
                                                _createVNode(_component_control_button, {
                                                  onClick: _withModifiers(open, ["prevent"])
                                                }, {
                                                  default: _withCtx(() => [
                                                    _createTextVNode(_toDisplayString($setup.$t("Вже у кошику")), 1)
                                                  ]),
                                                  _: 2
                                                }, 1032, ["onClick"])
                                              ])
                                            ]),
                                            _: 1
                                          })
                                        ]))
                                      : (_openBlock(), _createElementBlock("div", _hoisted_40, [
                                          _createElementVNode("div", _hoisted_41, [
                                            _createVNode(_component_control_button, {
                                              onClick: _withModifiers(props.addToCart, ["prevent"]),
                                              variant: ['blue', '2', '3']
                                            }, {
                                              default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString($setup.$t("Купити відео")), 1)
                                              ]),
                                              _: 2
                                            }, 1032, ["onClick"])
                                          ])
                                        ]))
                                  ]),
                                  _: 1
                                }, 8, ["item-id", "type", "period"]))
                              : (_openBlock(), _createElementBlock("div", _hoisted_42, [
                                  _createVNode(_component_control_button, {
                                    tag: "span",
                                    onClick: _withModifiers($setup.confirmRegistration, ["prevent"]),
                                    variant: ['blue', '2', '3']
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString($setup.$t("Отримати відео")), 1)
                                    ]),
                                    _: 1
                                  })
                                ]))
                          ], 64))
                        : _createCommentVNode("", true)
                    ], 64))
                  : _createCommentVNode("", true)
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_43, [
                ($props.actualPrice > 0)
                  ? (_openBlock(), _createBlock(_component_cart_button_add, {
                      key: 0,
                      "item-id": $props.eventId,
                      type: $props.cartType,
                      period: $setup.formdata.period.id
                    }, {
                      default: _withCtx((props) => [
                        (props.isInCart)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_44, [
                              _createVNode(_component_modal_trigger, {
                                component: "Cart/View",
                                classes: ['modal--size_xl']
                              }, {
                                default: _withCtx(({ open }) => [
                                  _createVNode(_component_control_button, {
                                    onClick: _withModifiers(open, ["prevent"])
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString($setup.$t("Вже у кошику")), 1)
                                    ]),
                                    _: 2
                                  }, 1032, ["onClick"])
                                ]),
                                _: 1
                              })
                            ]))
                          : (_openBlock(), _createElementBlock("div", _hoisted_45, [
                              _createVNode(_component_control_button, {
                                onClick: _withModifiers(props.addToCart, ["prevent"]),
                                class: _normalizeClass({ 'is-disabled': !$setup.formdata.period.id })
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString($setup.$t("Додати в кошик")), 1)
                                ]),
                                _: 2
                              }, 1032, ["onClick", "class"])
                            ]))
                      ]),
                      _: 1
                    }, 8, ["item-id", "type", "period"]))
                  : (_openBlock(), _createBlock(_component_control_button, {
                      key: 1,
                      tag: "span",
                      class: _normalizeClass({ 'is-disabled': !$setup.formdata.period.id }),
                      onClick: _withModifiers($setup.confirmRegistration, ["prevent"])
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString($setup.$t("Зареєструватися")), 1)
                      ]),
                      _: 1
                    }, 8, ["class"]))
              ]))
      ])
    ])
  ], 64))
}